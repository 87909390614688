import { pdf, PDFDownloadLink } from '@react-pdf/renderer';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getReceiptByReceiptId } from '../../api/firebase';
import { AuthContext } from '../../contexts/AuthContext';
import { useTheme } from '../../contexts/ThemeProvider';
import { IconBack } from '../../icons';
import { Notarization, NotarizationTransaction, Receipt } from '../../imports/types';
import { formatDate } from '../../imports/utils/date';
import { getExplorerTx } from '../../imports/utils/notarizations';
import { downloadFile, downloadReport } from '../../imports/utils/storage';
import Button from '../button/Button';
import ReportNotarization from '../reportNotarization/ReportNotarization';
import { saveAs } from 'file-saver';

type NotarizationElementProps = {
  notarization: Notarization & Receipt;
};

const NotarizationElement = ({ notarization }: NotarizationElementProps) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { getBaseIconColor } = useTheme();
  const [showMore, setShowMore] = useState(false);
  const [transactionData, setTransactionsData] = useState<Receipt>();
  const iconColor = getBaseIconColor();

  useEffect(() => {
    getReceiptByReceiptId(notarization.receiptId).then((res) => setTransactionsData(res));
  }, []);

  return (
    <div
      className="relative mb-5 flex w-full flex-col rounded-[10px] bg-grey-300 p-4 dark:bg-grey-500"
      key={notarization.hash}
    >
      {showMore ? (
        <div className="flex flex-col content-center justify-start gap-2">
          <div
            className="flex w-full items-center justify-between gap-1"
            onClick={() => setShowMore(!showMore)}
          >
            <p className="text-body-bold-20">{notarization.name}</p>{' '}
            <IconBack
              stroke={iconColor}
              width={24}
              height={24}
              style={{ transform: 'rotate(90deg)' }}
            />
          </div>
          {notarization.description && (
            <div>
              <span className="text-body-semibold-16 uppercase">
                {t('notarizations.description')}:
              </span>
              <p className="text-body-regular-14">{notarization.description}</p>
            </div>
          )}
          {notarization.date && (
            <div>
              <span className="text-body-semibold-16 uppercase">{t('notarizations.date')}:</span>{' '}
              <p className="text-body-regular-14">{formatDate(notarization.date)}</p>
            </div>
          )}
          {notarization.notes && (
            <div>
              <span className="text-body-semibold-16 uppercase">{t('notarizations.notes')}:</span>{' '}
              <p className="text-body-regular-14">{notarization.notes}</p>
            </div>
          )}
          {notarization.hash && (
            <div>
              <span className="text-body-semibold-16 uppercase">{t('notarizations.hash')}:</span>{' '}
              <p className="overflow-hidden break-all text-body-regular-14">{notarization.hash}</p>
            </div>
          )}
          {transactionData?.txHash && (
            <div>
              <p className="text-body-semibold-16 uppercase">
                {t('notarizations.blockchain_explorer_api')}:
              </p>{' '}
              <a
                href={getExplorerTx(transactionData?.txHash || '').toString()}
                target="_blank"
                rel="noreferrer"
                className="text-body-regular-14 underline"
              >
                {`${t('notarizations.go_to_explorer')} >`}
              </a>
            </div>
          )}
          <div className="mt-4 grid w-full grid-cols-2 gap-4">
            {(notarization.externalUrl || notarization.ipfsUri) && (
              <Button
                className="mx-0"
                type="secondary"
                action={() =>
                  downloadFile({
                    uri: notarization.externalUrl || notarization.ipfsUri || '',
                    storage: notarization.storageType,
                    fileName: notarization.name,
                  })
                }
              >
                {t('notarizations.download_file')}
              </Button>
            )}
            {transactionData?.tx && transactionData.status === 'success' && (
              <Button
                action={async () => {
                  const blob = await pdf(
                    <ReportNotarization
                      notarizations={notarization}
                      transactionData={transactionData.tx as NotarizationTransaction}
                    />
                  ).toBlob();
                  saveAs(blob, `${notarization.name}-Report`);
                }}
                type="primary"
                className="w-full"
              >
                {t('notarizations.download_report')}
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div
          className="flex w-full items-center justify-between gap-1"
          onClick={() => setShowMore(!showMore)}
        >
          <p className="text-body-bold-16">{notarization.name}</p>
          <IconBack
            stroke={iconColor}
            width={24}
            height={24}
            style={{ transform: 'rotate(-90deg)' }}
          />
        </div>
      )}
    </div>
  );
};

export default NotarizationElement;
